import "./App.css";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "onboarded-dashboard": any;
    }
  }
}

export const App = () => {
  return (
    <onboarded-dashboard
      admin="true"
      companyname="Fortress Pay"
      companyemail="info@fortress-pay.com"
      clientlogo="https://framerusercontent.com/images/ORbCGA985YzuDjaoXGvXJznysc.png?scale-down-to=512"
      clientlogosmall="https://fortresspaylogin.com/img/fortress_login@2x.jpg"
    />
  );
};
